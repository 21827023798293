<template>
  <div class="contentView height-100">
    <div class="centerView height-100 centerBox">
      <div class="navCont">
        <div class="tips" style="margin-bottom: 15px">
          <p style="font-size: 14px; line-height: 20px">
            <template v-if="locale == 'zh_CN'">
              本月已创建
              <span>{{ totalLive }}</span>
              场直播
            </template>
            <template v-else>
              {{ t('calendar.numLives') }}
              <span>{{ totalLive }}</span>
            </template>
          </p>
          <p style="font-size: 14px; line-height: 20px">
            <template v-if="locale == 'zh_CN'">
              已直播
              <span>{{ overLive }}</span>
              场
            </template>
            <template v-else>
              {{ t('calendar.lived') }}
              <span>{{ overLive }}</span>
            </template>
          </p>
          <!--      <p style="color: #ff4d4f;">* 日历展示为美国时间，点击排期日期可查看直播详情</p>-->
        </div>
        <div class="flexL">
          <a-select v-model:value="liveType" @change="handleChangeLiveType" style="margin-right: 15px; width: 200px; border-radius: 10px" size="large">
            <a-select-option :value="1">达播</a-select-option>
            <a-select-option :value="2">店播</a-select-option>
          </a-select>
          <a-month-picker :value="month" @change="onPanelChange" :allowClear="false">
            <template #suffixIcon>
              <img style="width: 15px; height: 15px; display: flex" src="@/assets/images/table/timePickIcon.png" alt="" />
            </template>
          </a-month-picker>
          <a-select v-model:value="bId" style="margin-left: 15px; width: 200px; border-radius: 10px" size="large">
            <a-select-option :value="item.idCode" v-for="(item, index) in storeList" :key="index">{{ item.name }}</a-select-option>
          </a-select>
          <a-input v-model:value="keywords" :placeholder="placeMes" style="margin-left: 15px; width: 200px" allowClear size="large" />

          <a-select
            optionFilterProp="key"
            v-model:value="influencerId"
            style="margin-left: 15px; width: 200px; border-radius: 10px"
            :placeholder="selectAnchor"
            allowClear
            show-search
            size="large"
          >
            <a-select-option :value="item.refCode" v-for="item in influencerList" :key="item.lastName">{{ item.lastName }}</a-select-option>
          </a-select>
        </div>
      </div>

      <a-calendar v-if="flag" :value="month" @panelChange="onPanelChange">
        <template #headerRender></template>
        <template #dateCellRender="{ current }">
          <div class="view flexTC absoluDiv">
            <div
              v-if="new Date().toDateString() == new Date(current).toDateString()"
              style="left: 1px; right: 1px; position: absolute; top: -1px; height: 4px; border-radius: 100px 100px 0 0; background: #ff6700; z-index: 999"
            ></div>
            <template v-if="!loadRef">
              <div v-if="getListData(current)" class="flexTCs" :class="{ background: getListData(current) }" @click="toDetailList(current)">
                <p :style="{ 'font-size': locale == 'zh_CN' ? '14px' : '12px' }">
                  <template v-if="locale == 'zh_CN'">
                    &nbsp;&nbsp;&nbsp;&nbsp;总场次：
                    <span>{{ getListData(current).totalLive }}</span>
                    场
                  </template>
                  <template v-else>
                    {{ t('calendar.session') }}
                    <span>{{ getListData(current).totalLive }}</span>
                  </template>
                </p>
                <p style="margin: 6px 0" :style="{ 'font-size': locale == 'zh_CN' ? '14px' : '12px' }">
                  <template v-if="locale == 'zh_CN'">
                    已播场次：
                    <span>{{ getListData(current).overLive }}</span>
                    场
                  </template>
                  <template v-else>
                    {{ t('calendar.sessioning') }}
                    <span>{{ getListData(current).overLive }}</span>
                  </template>
                </p>
                <p :style="{ 'font-size': locale == 'zh_CN' ? '14px' : '12px' }">
                  <template v-if="locale == 'zh_CN'">
                    &nbsp;&nbsp;&nbsp;&nbsp;商品数：
                    <span>{{ getListData(current).totalGoods }}</span>
                    款
                  </template>
                  <template v-else>
                    {{ t('calendar.shopNums') }}
                    <span>{{ getListData(current).totalGoods }}</span>
                  </template>
                </p>
              </div>
            </template>

            <div v-else style="left: 1px; right: 1px; position: absolute; top: -1px; width: 100%; height: 100%">
              <a-space :loading="false" :active="true">
                <a-skeleton-button :active="true" style="width: 100%; height: 131px; z-index: 999999; border-radius: 22px"></a-skeleton-button>
              </a-space>
            </div>
          </div>
        </template>
      </a-calendar>
    </div>
  </div>
</template>

<script>
  import { reactive, ref, toRefs, watch, computed } from 'vue'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import { useRouter } from 'vue-router'
  import { cachePageParameters } from '../../../utils/util'
  import { getCalendarMonth, getStoreList, allInfluencer } from '../../../api/live'
  import { useI18n } from 'vue-i18n'
  export default {
    name: 'liveCalendar',
    setup() {
      const loadRef = ref(true)
      const { t, locale } = useI18n()
      // moment.locale('zh-cn');
      const router = useRouter()
      let store = useStore()
      let data = reactive({
        flag: true,
        month: moment(Date.now()).format('YYYY-MM'),
        bId: null,
        storeList: [],
        listData: {},
        totalLive: 0,
        overLive: 0,
        keywords: '',
        influencerList: [],
        influencerId: null,
        liveType: 1
      })
      console.log(data.month, '打印月份事件')
      cachePageParameters(null, res => {
        loadRef.value = true
        for (let i in res) {
          data[i] = res[i]
        }
        setTimeout(() => {
          loadRef.value = false
        }, 800)
      })
      const placeMes = computed(() => t('asin'))
      const selectAnchor = computed(() => t('selectAnchor'))
      // if(data.storeList.length === 0 && !data.bId)
      getStoreListFn()
      // getCalendarMonthFn();
      function getStoreListFn() {
        getStoreList().then(res => {
          if (res.code === 200) {
            data.storeList = res.data
            if (!data.bId) {
              data.bId = res.data[0].idCode
            }
            allInfluencer(
              {
                month: moment(data.month).format('YYYY-MM'),
                type: data.liveType
              },
              data.bId
            ).then(res => {
              data.influencerList = res.data
            })
          }
        })
      }
      watch(
        () => data.bId,
        () => {
          getCalendarMonthFn()
          data.influencerId = null
          allInfluencer(
            {
              month: moment(data.month).format('YYYY-MM'),
              type: data.liveType
            },
            data.bId
          ).then(res => {
            data.influencerList = res.data
          })
        }
      )
      watch(
        () => data.month,
        () => {
          getCalendarMonthFn()
          data.influencerId = null
          allInfluencer(
            {
              month: moment(data.month).format('YYYY-MM'),
              type: data.liveType
            },
            data.bId
          ).then(res => {
            data.influencerList = res.data
          })
        }
      )
      watch(
        () => data.keywords,
        () => {
          getCalendarMonthFn()
        }
      )
      watch(
        () => data.influencerId,
        () => {
          getCalendarMonthFn()
        }
      )
      function getCalendarMonthFn() {
        loadRef.value = true
        data.flag = false
        store.commit('openLoading', true)
        getCalendarMonth(
          {
            month: moment(data.month).format('YYYY-MM'),
            keywords: data.keywords,
            influencerId: data.influencerId,
            type: data.liveType
          },
          data.bId
        ).then(res => {
          console.log(res, 'res-123-res')
          if (res.code === 200) {
            loadRef.value = false
            // res.data.map(item=>{
            //   console.log(item)
            //   // data.overLive += item.overLive;
            //   // data.totalLive += item.totalLive;
            // })
            data.overLive = 0
            data.totalLive = 0
            for (let key in res.data) {
              let item = res.data[key]
              data.overLive += item.overLive
              data.totalLive += item.totalLive
            }
            data.listData = res.data
            data.flag = true
            store.commit('openLoading', false)
            cachePageParameters({
              bId: data.bId,
              storeList: data.storeList,
              month: data.month,
              listData: data.listData,
              keywords: data.keywords,
              influencerId: data.influencerId
            })
          }
        })
      }
      const getListData = value => {
        return data.listData[moment(value).format('YYYY-MM-DD')]
      }
      function onPanelChange(date) {
        data.month = moment(date).format('YYYY-MM')
        console.log(data.month)
      }
      /**
       * 直播类型切换
       */
      const handleChangeLiveType = () => {
        getStoreListFn()
      }
      //点击日期跳转到详情列表
      const toDetailList = current => {
        console.log(current, 'current----current')
        router.push({
          path: '/live/calendar/list',
          query: {
            time: current,
            staoreId: data.bId
          }
        })
      }
      return {
        t,
        placeMes,
        selectAnchor,
        locale,
        toDetailList,
        getListData,
        ...toRefs(data),
        onPanelChange,
        handleChangeLiveType,
        loadRef
      }
    }
  }
</script>

<style>
  .ant-space-item {
    width: 100%;
  }
  .ant-skeleton.ant-skeleton-active .ant-skeleton-button {
    border-radius: 8px;
  }
  /* .ant-input {
  border-radius: 10px;
} */
  .ant-select {
    border-radius: 10px;
  }
  .ant-calendar-month-panel {
    border-radius: 8px;
  }
  .ant-calendar {
    border: 1px solid #ffc299;
    border-radius: 8px;
  }
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
    background: #ff6700;
  }
  .ant-calendar-header a:hover {
    color: #fff;
  }
  .ant-calendar-month-panel-month:hover {
    background: #ff6700;
    cursor: pointer;
  }
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
    background: #ff6700;
  }
  .ant-fullcalendar-fullscreen .ant-fullcalendar-value {
    font-size: 14px !important;
  }
</style>
<style scoped lang="less">
  ::v-deep(.ant-space-align-center) {
    width: 100%;
    padding: 0 !important;
    border: none !important;
  }
  ::v-deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    border-radius: 10px !important;
  }
  .flexTCs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 16px !important;
    padding-top: 55px !important;
  }
  .absoluDiv {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #ededed;
    border-radius: 10px;
  }
  ::v-deep(.ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover) {
    background: #fff5ec;
    border-radius: 10px;
    border: 1px solid #fee5d4;
  }
  .absoluDiv:hover {
    border: 1px solid #fee5d4;
  }
  ::v-deep(.ant-fullcalendar-selected-day .ant-fullcalendar-date) {
    background: #fff5ec;
    border-radius: 10px;
    border: 1px solid #fee5d4;
  }
  ::v-deep(.ant-fullcalendar-selected-day .absoluDiv) {
    border: 1px solid #fee5d4;
  }
  ::v-deep(.ant-fullcalendar-selected-day .ant-fullcalendar-value) {
    color: rgba(0, 0, 0, 0.85);
  }
  .centerBox {
    padding: 0 32px 20px;
    min-width: 1250px;
  }
  .navCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 57px;
  }
  p {
    margin: 0;
    padding: 0;
  }
  .background {
    background: #fff5ec;
    // width: 100%;
    // height: 100%;
    position: absolute;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    padding: 10px;
    overflow-y: scroll;
    border: 1px solid #fee5d4;
    border-radius: 10px;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
  .view {
    width: 100%;
    height: 100%;
    //height: 100px;
    font-size: 14px;

    p {
      margin: 0px 0;
      font-size: 14px;
      line-height: 14px;
      span {
        font-size: 14px;
        line-height: 14px;
        margin: 0 4px;
        color: #ff6700;
      }
    }
  }
  .height-100 {
    height: 100% !important;
    min-height: 1100px !important;
  }

  ::v-deep(.ant-fullcalendar-fullscreen) {
    // height: 97%;
    .ant-fullcalendar-calendar-body {
      padding: 8px 0;
      height: 100%;
      .ant-fullcalendar-table {
        height: 100%;
        thead {
          background: #f7f7f7;
          height: 40px;
        }
        .ant-fullcalendar-tbody {
          tr {
            height: 15% !important;
          }
        }

        .ant-fullcalendar-date {
          // height: 100%;
          height: 132px;
          // overflow: hidden;
          // border-radius: 10px;
          // border: 2px solid #f0f0f0;
          border: none;
          margin: 8px 8px 0 0 !important;
          position: relative;
          .ant-fullcalendar-content {
            height: 85%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 10px;
            &::-webkit-scrollbar {
              width: 0px;
            }
          }
        }
      }
    }
    .ant-fullcalendar-header {
      overflow: hidden;
      display: none;
    }
    .ant-radio-group {
      display: none;
      margin-bottom: 20px;
    }
    .ant-fullcalendar-value {
      text-align: left;
      font-size: 18px;
      position: relative;
      z-index: 999;
    }
    .ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
      padding-left: 12px;
      // padding-bottom: 5px;
      text-align: left;
      font-size: 18px;
    }
  }
  tbody tr td:nth-child(1) .ant-fullcalendar-date {
    margin-left: 0 !important;
  }
</style>
